import React from "react"
import { Flex, Box, Label } from "../system"
import { GatsbyImage } from "gatsby-plugin-image"
import { Cross1Icon } from "@modulz/radix-icons"

const CartItem = ({ id, remove }) => (
  <Flex flexDirection={["row"]} justifyContent="space-between" my={3}>
    <Box width={150}>
      <GatsbyImage image={id.img} alt={id.name} />
    </Box>
    <Flex ml={3} flexDirection="column" alignItems="flex-start">
      <Label
        down
        textAlign="left"
        fontWeight="normal"
        // mb={1}
        fontSize={1}
        lineHeight="normal"
      >
        {id.name}
      </Label>
      <Label color="faded" fontSize={1} down>
        {id.brand}
      </Label>
      <Label down mt={1} fontSize={1}>
        Size: {id.size}
      </Label>
      <Label color="secondary">£ {id.price}</Label>
    </Flex>
    <Flex
      onClick={() => remove(id.cartId)}
      height={30}
      width={30}
      minWidth={30}
      borderRadius={15}
      style={{ cursor: "pointer" }}
    >
      <Cross1Icon color="#cecece" />
    </Flex>
  </Flex>
)

export default CartItem
