import React from "react"
import { Grid, Flex, Title, Label, FooterLink, Text } from "../system"
import {
  Logo,
  ApplePay,
  GooglePay,
  MasterCard,
  Visa,
  AmericanExpress,
  Twitter,
  Instagram,
  Facebook,
  Desc,
} from "../Icons"

const Footer = () => {
  const date = new Date()
  const year = date.getFullYear()
  return (
    <>
      <Grid
        p={[4, 5]}
        bg="black"
        color="white"
        gridGap={[50, 30, 80]}
        gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"]}
      >
        <Flex
          alignItems="flex-start"
          justifyContent="flex-start"
          flexDirection="column"
        >
          <Title fontSize={4} mb={3}>
            Social
          </Title>
          <Flex alignItems="flex-start" justifyContent="flex-start">
            <a
              href="https://www.instagram.com/ilana.uk"
              style={{ marginRight: 25 }}
            >
              <Instagram color="#77777a" />
            </a>
            <a
              href="https://www.twitter.com/ilanaafrica"
              style={{ marginRight: 25 }}
            >
              <Twitter color="#77777a" />
            </a>
            <a
              href="https://www.facebook.com/ilanauk"
              style={{ marginRight: 25 }}
            >
              <Facebook color="#77777a" />
            </a>
          </Flex>
          <Flex
            mt={3}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Label down color="#77777a">
              0203 916 5397
            </Label>
            <Label down color="#77777a">
              hi@ilana.uk
            </Label>
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          mb={[0, 4]}
        >
          <Title fontSize={4} mb={3}>
            Store
          </Title>
          <FooterLink to="/about">About us</FooterLink>
          <FooterLink to="/FAQ">FAQ</FooterLink>
          <FooterLink to="/return-policy">Shipping & Returns</FooterLink>
          <FooterLink to="/press">Press</FooterLink>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          mb={[0, 4]}
        >
          <Title fontSize={4} mb={3}>
            Legal
          </Title>
          <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
          <FooterLink to="/terms-and-conditions">Terms & Conditions</FooterLink>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          mb={[0, 4]}
        >
          <Title fontSize={4} mb={3}>
            Community
          </Title>
          <FooterLink to="/join">Join</FooterLink>
          <FooterLink to="/community">Login</FooterLink>
        </Flex>
      </Grid>
      <Grid
        p={[4, 5]}
        bg="black"
        color="white"
        gridGap={[50, 30, 80]}
        gridTemplateColumns={["1fr", "1fr", "1fr 1fr 1fr"]}
      >
        <Flex
          flexDirection="column"
          alignItems={["center", "center", "flex-start"]}
        >
          <Logo size={60} />
          <Label down color="#77777a" mt={2} fontSize={1}>
            © {year} Ilana Ltd.
          </Label>
        </Flex>

        <Flex flexDirection="column">
          <Flex justifyContent="space-between" alignSelf="flex-end">
            <ApplePay color="#77777a" />
            <GooglePay color="#77777a" />
            <MasterCard color="#77777a" />
            <Visa color="#77777a" />
            <AmericanExpress color="#77777a" />
          </Flex>
          <Label down color="#77777a" fontSize={1} mt={3} textAlign="center">
            Ilana is a registered trademark of Ilana Ltd.
          </Label>
        </Flex>

        <Flex
          flexDirection="column"
          alignItems={["center", "center", "flex-end"]}
          justifyContent="flex-end"
        >
          <Label down color="#77777a" mb={2} fontSize={1}>
            Powered by
          </Label>
          <a href="https://descglobal.com">
            <Desc size={100} />
          </a>
        </Flex>
      </Grid>
    </>
  )
}

export default Footer
