import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { motion } from "framer-motion"

import { Context } from "../../utils/Provider"
import { Flex, Label, MenuLink, LoginLink } from "../system"
import useWindowSize from "../../utils/useWindowSize"
import { Logo, Cart, Mark, Burger, Close } from "../Icons"

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { x: "+100%" },
}

const scrolling = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
}

const Menu = ({ dark, logo }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [hidden, hide] = useState(false)
  const [state, dispatch] = useContext(Context)
  const { loginWithRedirect } = useAuth0()
  const { width } = useWindowSize()

  let lastScrollTop = 0

  const handleScroll = scroll => {
    const currentScroll = window.pageYOffset
    if (currentScroll > lastScrollTop) {
      hide(true)
    } else {
      hide(false)
    }
    lastScrollTop = currentScroll <= 0 ? 0 : currentScroll
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <>
      <TopMenu
        bg="primary"
        color="white"
        height={40}
        position="fixed"
        top={0}
        left={0}
        zIndex={4}
        width="100vw"
        initial="open"
        animate={hidden ? "hidden" : "visible"}
        variants={scrolling}
        transition={{ duration: 0.6, ease: [0.19, 1.0, 0.22, 1.0] }}
      >
        <Label down fontSize={[1, 2]}>
          Free International Shipping
        </Label>
      </TopMenu>
      <TopMenu
        px={[3, 4]}
        height={[80, 120]}
        width="100vw"
        justifyContent="space-between"
        alignItems="center"
        position="fixed"
        zIndex={3}
        top={40}
        initial="open"
        animate={hidden ? "hidden" : "visible"}
        variants={scrolling}
        transition={{ duration: 0.6, ease: [0.19, 1.0, 0.22, 1.0] }}
      >
        <Link to="/">
          <Logo
            color={isOpen ? "transparent" : dark ? "#5C4030" : "white"}
            size={width < 426 ? 60 : 80}
          />
        </Link>

        <Flex alignItems="center" justifyContent="flex-end">
          <Flex
            width={50}
            bg="black"
            height={50}
            mr={3}
            borderRadius={25}
            onClick={() => setIsOpen(!isOpen)}
            style={{ cursor: "pointer" }}
          >
            {isOpen ? (
              <Close color="white" size={20} />
            ) : (
              <Burger color="white" size={25} />
            )}
          </Flex>

          <Link to="/join">
            <Flex
              bg="black"
              height={50}
              width={50}
              mr={3}
              borderRadius={25}
              style={{ cursor: "pointer" }}
            >
              <Mark size={25} color="white" />
            </Flex>
          </Link>

          <Flex
            bg="black"
            height={50}
            width={50}
            borderRadius={25}
            style={{ cursor: "pointer" }}
            onClick={() =>
              dispatch({ type: "TOGGLE_CART", payload: !state.windowOpen })
            }
          >
            <Cart size={30} />
          </Flex>
        </Flex>
      </TopMenu>
      <Hidden
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={variants}
        transition={{ duration: 0.6, ease: [0.19, 1.0, 0.22, 1.0] }}
        position="fixed"
        zIndex={2}
        height="100vh"
        width="100vw"
        bg="#000"
        color="white"
        flexDirection="column"
        alignItems="center"
        px={[4, 5, 6, 7]}
      >
        <Flex alignItems="flex-start" flexDirection={["column", "row"]}>
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            mb={[4, 0]}
          >
            <Label color="secondary" mb={4}>
              Shop
            </Label>
            <MenuLink to="/shop/men" fontSize={[5, 6]}>
              Men
            </MenuLink>
            <MenuLink to="/shop/women" fontSize={[5, 6]}>
              Women
            </MenuLink>
            {/* <MenuLink to="/shop/accessories" fontSize={[5, 6]}>
              Accessories
            </MenuLink> */}
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Label color="secondary" mb={4}>
              Community
            </Label>
            <MenuLink to="/join" fontSize={[5, 6]}>
              Join
            </MenuLink>
            <LoginLink onClick={() => loginWithRedirect()} fontSize={[5, 6]}>
              Login
            </LoginLink>
            <MenuLink to="/about" fontSize={[5, 6]}>
              About Us
            </MenuLink>
          </Flex>
        </Flex>
      </Hidden>
    </>
  )
}

export default Menu

const Hidden = motion.custom(Flex)

const TopMenu = motion.custom(Flex)
