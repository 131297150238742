import React, { useContext, useState, useEffect } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { v4 as uuidv4 } from "uuid"
import { Flex, Title, Button, Label, Box } from "../system"
import { motion } from "framer-motion"
import { Context } from "../../utils/Provider"
import { Cross1Icon } from "@modulz/radix-icons"

import CartItem from "../CartItem"

const testLineItems = [
  {
    price: "price_1IAKqgKUbUJGGRMf1tgQKY7e",
    quantity: 1,
  },
  {
    price: "price_1HpBOuKUbUJGGRMf0Vc5xEN0",
    quantity: 1,
  },
  {
    price: "price_1HjsMxKUbUJGGRMfWGjREgza",
    quantity: 1,
  },
]

const variants = {
  open: { x: 0 },
  closed: { x: "100%" },
}

const fade = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
}

const CartWindow = () => {
  const [state, dispatch] = useContext(Context)
  const [loading, setLoading] = useState(false)

  const uuid = uuidv4()

  let box = []

  const lineItems = state?.cart.map((item, key) => {
    if (box.some(product => product.price === item.stripe)) {
      const product = box.filter(p => p.price === item.stripe)
      const without = box.filter(p => p.price !== item.stripe)

      // remove from array then push with updated quantity
      without.push({
        price: item.stripe,
        quantity: product[0].quantity + 1,
      })
      box = without
    } else {
      box.push({
        price: item.stripe,
        quantity: 1,
      })
    }
    return {
      price: item.stripe,
      quantity: 1,
      tax_rates: ["txr_1IVJFcKUbUJGGRMf4YtUj0Rp"],
    }
  })

  const items = state?.cart.map((item, key) => {
    return {
      _key: item.name,
      _type: "item",
      product: {
        _ref: item.id,
      },
      size: item.size,
      quantity: 1,
    }
  })

  const prices = state?.cart.map(item => {
    return item.price
  })

  const sum = arr => {
    return arr?.reduce((a, b) => {
      return a + b
    }, 0)
  }

  useEffect(() => {
    if (localStorage.getItem("products")) {
      const products = JSON.parse(localStorage.getItem("products"))
      dispatch({ type: "SET_CART", payload: products })
    }
  }, [dispatch])

  const remove = id => {
    let storageProducts = JSON.parse(localStorage.getItem("products"))
    let products = storageProducts.filter(product => product.cartId !== id)
    localStorage.setItem("products", JSON.stringify(products))
    dispatch({ type: "SET_CART", payload: products })
  }

  const redirectToCheckout = async e => {
    e.preventDefault()
    setLoading(true)

    // create order in sanity
    const mutations = [
      {
        createOrReplace: {
          _id: uuid,
          _type: "order",
          id: uuid,
          paid: false,
          items: items,
        },
      },
    ]

    await fetch(
      `https://${process.env.GATSBY_SANITY_PROJECT_ID}.api.sanity.io/v1/data/mutate/${process.env.GATSBY_SANITY_DATASET}`,
      {
        method: "post",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${process.env.GATSBY_SANITY_TOKEN}`,
        },
        body: JSON.stringify({ mutations }),
      }
    )
      .then(response => response.json())
      .catch(error => console.error(error))
    // create order in sanity

    const stripe = await loadStripe(process.env.GATSBY_STRIPE_PUBLIC)
    await fetch("/.netlify/functions/checkout", {
      method: "post",
      body: JSON.stringify({
        mode: "payment",
        clientReferenceId: uuid,
        lineItems: box,
        allowedCountries: ["GB", "US"],
        successUrl: process.env.GATSBY_SUCCESS_URL,
        cancelUrl: window.location.href,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        stripe
          .redirectToCheckout({
            sessionId: data,
          })
          .then(() => setLoading(false))
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <>
      <Blackout
        onClick={() =>
          dispatch({ type: "TOGGLE_CART", payload: !state?.windowOpen })
        }
        position="fixed"
        top={0}
        left={0}
        height="100vh"
        width="100vw"
        bg="rgba(0,0,0,0.7)"
        style={{
          pointerEvents: state?.windowOpen ? "auto" : "none",
        }}
        variants={fade}
        initial="closed"
        animate={state?.windowOpen ? "open" : "closed"}
        transition={{ duration: 0.6, ease: [0.19, 1.0, 0.22, 1.0] }}
        zIndex={3}
      />
      <Window
        animate={state?.windowOpen ? "open" : "closed"}
        variants={variants}
        transition={{ duration: 0.6, ease: [0.19, 1.0, 0.22, 1.0] }}
        initial="closed"
        bg="white"
        height="100vh"
        width={["100vw", 450]}
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        p={[3, 4]}
        position="fixed"
        top={0}
        right={0}
        zIndex={5}
        overflowY="scroll"
      >
        <Flex alignItems="flex-start" justifyContent="space-between">
          <Box>
            <Title mb={2} fontSize={5}>
              Cart
            </Title>
            {!state?.cart.length === 0 && (
              <Label color="faded" down mb={3}>
                {state?.cart.length === 1
                  ? "1 Item"
                  : `${state?.cart.length} Items`}
              </Label>
            )}
          </Box>
          <Flex
            onClick={() =>
              dispatch({ type: "TOGGLE_CART", payload: !state.windowOpen })
            }
            height={50}
            width={50}
            borderRadius={25}
            bg="black"
            style={{ cursor: "pointer" }}
          >
            <Cross1Icon color="white" />
          </Flex>
        </Flex>
        {state?.cart.length === 0 ? (
          <Flex height="90vh" px={[3, 5]}>
            <Title fontSize={5}>You haven't added anything yet</Title>
          </Flex>
        ) : (
          <>
            <Flex flexDirection="column" justifyContent="flex-start">
              {state?.cart.map((item, key) => (
                <CartItem id={item} key={key} remove={remove} />
              ))}
            </Flex>
            <Flex flexDirection="column" mt={4}>
              <Flex justifyContent="space-between" py={3}>
                <Label>Total</Label>
                <Label>£{sum(prices)?.toFixed(2)}</Label>
              </Flex>
              {/* <Button
                onClick={() =>
                  dispatch({ type: "TOGGLE_CART", payload: !state?.windowOpen })
                }
                bg="faded"
                width="100%"
                mb={3}
                >
                Keep Shopping
              </Button> */}
              <Button
                onClick={e => redirectToCheckout(e)}
                bg="black"
                hbg="secondary"
                color="white"
                hcolor="white"
                width="100%"
              >
                {loading ? "Processing..." : "Checkout"}
              </Button>
            </Flex>
          </>
        )}
      </Window>
    </>
  )
}

export default CartWindow

const Window = motion.custom(Flex)
const Blackout = motion.custom(Box)
