import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import theme from "./theme"

import "./layout.css"

import Menu from "./Menu"
import CartWindow from "./CartWindow"
import Footer from "./Footer"

const Layout = ({ children, dark }) => {
  return (
    <ThemeProvider theme={theme}>
      <CartWindow />
      <Menu dark={dark} />
      {children}
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
