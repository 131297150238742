import React from "react"

export default function useWindowSize() {
  const isSSR = typeof window !== "undefined"
  const [windowSize, setWindowSize] = React.useState({
    width: isSSR ? 1200 : typeof window !== "undefined" && window.innerWidth,
    height: isSSR ? 800 : typeof window !== "undefined" && window.innerHeight,
  })

  function changeWindowSize() {
    setWindowSize({
      width: typeof window !== "undefined" && window.innerWidth,
      height: typeof window !== "undefined" && window.innerHeight,
    })
  }

  React.useEffect(() => {
    window.addEventListener("resize", changeWindowSize)

    return () => {
      window.removeEventListener("resize", changeWindowSize)
    }
  }, [])

  return windowSize
}
